<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <van-swipe :autoplay="3000" class="swipe-box">
        <van-swipe-item>
          <img src="../../assets/img/tmp_1.jpg" class="swipe-box-img" />
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/img/tmp_1.jpg" class="swipe-box-img" />
        </van-swipe-item>
      </van-swipe>
      <div class="health-box">
        <div class="health-box-banner">
          <div class="health-box-banner-left">
            <h3>个人量身定制体检</h3>
            <p>煽动的话煽动的话煽动的话煽</p>
          </div>
          <div class="health-box-banner-right">
            <img src="../../assets/img/risk/img_ai_avatar@2x.png" />
          </div>
        </div>
        <h3>您的健康风险</h3>
        <div class="health-box-risk">
          <div class="health-box-risk-item">
            <h3>高血压</h3>
            <p>既往史</p>
          </div>
          <div class="health-box-risk-item">
            <h3>高血压</h3>
            <p>既往史</p>
          </div>
          <div class="health-box-risk-item">
            <h3>高血压</h3>
            <p>既往史</p>
          </div>
          <div class="health-box-risk-item">
            <h3>高血压</h3>
            <p>既往史</p>
          </div>
          <div class="health-box-risk-item">
            <h3>高血压</h3>
            <p>既往史</p>
          </div>
        </div>

        <div class="health-box-btn" @click="onTap">
          配置适合您的健康体检方案
        </div>
      </div>
      <div class="health-plan">
        <van-tabs v-model="active" scrollspy sticky>
          <van-tab title="关爱老人">
            <h3>关爱老人</h3>
            <div class="health-plan-box">
              <img src="../../assets/img/tmp-2@2x.jpg" />
              <img src="../../assets/img/tmp-3@2x.jpg" />
            </div>
          </van-tab>
          <van-tab title="关爱老人">
            <h3>关爱老人</h3>
            <div class="health-plan-box">
              <img src="../../assets/img/tmp-2@2x.jpg" />
              <img src="../../assets/img/tmp-3@2x.jpg" />
            </div>
          </van-tab>
          <van-tab title="关爱老人">
            <h3>关爱老人</h3>
            <div class="health-plan-box">
              <img src="../../assets/img/tmp-2@2x.jpg" />
              <img src="../../assets/img/tmp-3@2x.jpg" />
            </div>
          </van-tab>
          <van-tab title="关爱老人">
            <h3>关爱老人</h3>
            <div class="health-plan-box">
              <img src="../../assets/img/tmp-2@2x.jpg" />
              <img src="../../assets/img/tmp-3@2x.jpg" />
            </div>
          </van-tab>
          <van-tab title="关爱老人">
            <h3>关爱老人</h3>
            <div class="health-plan-box">
              <img src="../../assets/img/tmp-2@2x.jpg" />
              <img src="../../assets/img/tmp-3@2x.jpg" />
            </div>
          </van-tab>
          <van-tab title="关爱老人">
            <h3>关爱老人</h3>
            <div class="health-plan-box">
              <img src="../../assets/img/tmp-2@2x.jpg" />
              <img src="../../assets/img/tmp-3@2x.jpg" />
            </div>
          </van-tab>
          <van-tab title="关爱老人">
            <h3>关爱老人</h3>
            <div class="health-plan-box">
              <img src="../../assets/img/tmp-2@2x.jpg" />
              <img src="../../assets/img/tmp-3@2x.jpg" />
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      loading: false,
      id: null,
      images: ["../../../assets/img/ai_doc-clock.png"],
      active: 0,
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {},
  methods: {
    onTap() {
      this.$router.push({ path: "/health-check-form" });
    },
  },
};
</script>

<style scoped>
.g_main_content {
  background: #f2f7f8;
}
.health-plan {
  background: #fff;

  & h3 {
    font-size: 15px;
    font-weight: 500;
    margin: 20px 0px;
    padding: 0px 16px;
  }
}

.health-plan-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 16px;

  & img {
    width: calc((100% - 16px) / 2);
  }
}

.swipe-box {
  height: 208px;
  background: #eee;
}
.swipe-box-img {
  width: 100%;
  background: #eee;
}
.health-box {
  background: #fff;
  margin: 16px;
  padding: 20px 14px;
  margin-top: -40px;
  position: relative;
  border-radius: 20px;

  & h3 {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.4;
  }
}

.health-box-risk {
  width: 100%;
  margin-top: 14px;
  overflow-x: auto;
  display: -webkit-box;
  padding: 10px 0px;
}

.health-box-risk-item {
  width: 100px;
  margin-right: 10px;
  background: #effbff;
  padding: 10px;
  height: 50px;
  border-radius: 10px;

  & h3 {
    font-size: 14px;
    color: #242938;
  }

  & p {
    color: #868594;
    font-size: 12px;
    line-height: 1.2;
  }
}

.health-box-btn {
  width: 100%;
  height: 44px;
  line-height: 44px;
  border-radius: 22px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
  background-image: linear-gradient(to right, #67b6ff, #1890ff);
}

.health-box-banner {
  height: 68px;
  background: #fce9c6;
  background-image: linear-gradient(to right, #fdf6e4, #fce9c6);
  margin-bottom: 20px;
  overflow: hidden;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.health-box-banner-left {
  width: 64%;
  padding-left: 10px;
  color: #5c3215;
  font-size: 12px;
  line-height: 1.8;

  & h3 {
    font-weight: 500;
    font-size: 16px;
    color: #5c3215;
  }
}

.health-box-banner-right {
  width: 30%;

  & img {
    width: 60px;
    margin-top: 20px;
    margin-left: 20px;
  }
}
</style>
